import React, { Component } from 'react';
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import moment from 'moment';
import Modal from "react-modal";
import Loader from '../../../utils/Loader'
import { useNavigate, Link } from "react-router-dom";
// import Footer from '../../common_components/Footer';
import VisitorHeader from '../../../common_components/visitor_module_header';



class VisitorThankYou extends Component {
    // Intialized Constructor
    constructor(props) {
        super(props)
        this.state = {
            isLoading: false,
            geturl: "",
            is_partner: false,
            exhibitionAdd: '',
            exhibitionName: '',
            ExhRegistrationNo: '',
            fromDate: '',
            toDate: '',

            isClickValidation: false

        }
        this.onPreviousClick = this.onPreviousClick.bind(this)

    }

    componentWillUnmount() {
        const { isClickValidation } = this.state
        if (!isClickValidation) {
            sessionStorage.clear()
            window.location.replace('http://localhost:3000/#/');
        }
    }

    // On Component Load
    componentDidMount() {
        console.log('JSON.parse(sessionStorage.getItem()', (sessionStorage.getItem('VisitorDataSS')))
        sessionStorage.setItem('completed', true);
        console.log('route : ', this.props);
        // Exhibition From Date
        const dateString = sessionStorage.getItem('exhibitionDateFrom');
        const dateStringMoment = moment.utc(dateString).format('DD MMMM YYYY')
        const dateObject = new Date(dateStringMoment);
        const fromDay = dateObject.getDate();
        const fromMonth = dateObject.toLocaleString('default', { month: 'long' });
        const fromYear = dateObject.getFullYear();
        const ExfromDate = `${fromDay} ${fromMonth} ${fromYear}`;
        console.log('dateString', dateString)
        console.log('dateStringMoment', dateStringMoment)
        console.log('dateObject', dateObject)

        // Exhibition to Date
        const dateString1 = sessionStorage.getItem('exhibitionDateTo');
        const dateString1Moment = moment.utc(dateString1).format('DD MMMM YYYY')
        const dateObject1 = new Date(dateString1Moment);
        const toDay = dateObject1.getDate();
        const toMonth = dateObject1.toLocaleString('default', { month: 'long' });
        const toYear = dateObject1.getFullYear();
        const ExtoDate = `${toDay} ${toMonth} ${toYear}`;
        this.setState({
            geturl: (sessionStorage.getItem('sessionExhMailWebsite') != null && sessionStorage.getItem('sessionExhMailWebsite') != '') ? sessionStorage.getItem('sessionExhMailWebsite') : '',
            // is_partner: this.props.location.state != null && this.props.location.state != "" && this.props.location.state.is_partner != " " ? this.props.location.state.is_partner : '',
            exhibitionAdd: (sessionStorage.getItem('exhibitionAddress') != null && sessionStorage.getItem('exhibitionAddress') != '') ? sessionStorage.getItem('exhibitionAddress') : '',
            exhibitionName: (sessionStorage.getItem('sessionExhibitionName') != null && sessionStorage.getItem('sessionExhibitionName') != '') ? sessionStorage.getItem('sessionExhibitionName') : '',
            // ExhRegistrationNo: exhno,
            fromDate: (sessionStorage.getItem('exhibitionDateFrom') != null && sessionStorage.getItem('exhibitionDateFrom') != '') ? ExfromDate : '',
            toDate: (sessionStorage.getItem('exhibitionDateTo') != null && sessionStorage.getItem('exhibitionDateTo') != '') ? ExtoDate : '',
        })
    }

    // On Component Close
    // componentWillUnmount() {
    //     sessionStorage.clear()
    // }

    // On Previous
    onPreviousClick(e) {
        e.preventDefault();
        this.setState({ isClickValidation: true }, () => {
            const win = window.location.replace(this.state.geturl, '_blank');
            if (win != null) {
                win.focus();
            }
        })
    }

    render() {
        const { isLoading, exhibitionAdd, exhibitionName, fromDate, toDate } = this.state

        return (
            <>
                {isLoading ? (
                    <Loader></Loader>
                ) : (null)}
                <ToastContainer />
                {/* <!-- PAGE --> */}
                <VisitorHeader />
                {/* <!-- CONTAINER --> */}
                <div >
                    {/* <StepHeader /> */}

                    {/* <!-- ROW-1 --> {geturl}*/}
                    <div
                        className="main-content app-content mt-0">
                        <div className="side-app">
                            <div className="main-container container-fluid top-form-name">
                                <div className="row ">
                                    <form className="needs-validation " noValidate="">
                                        <div className="form-row mt-4">
                                            <div className="form-group col-md-6 p-4 thank-box" style={{ background: "#f6f6f6" }}>
                                                <div className="form-group mb-2">
                                                    <h4
                                                        className="mb-0 pt-2 pb-4 thank-top-mes new-thanks1"
                                                        style={{ color: "#318958" }}
                                                    >
                                                        <strong>Visitor Registration Form Submitted Successfully</strong>
                                                    </h4>
                                                    <p className="mb-3 mt-2 text-center com-font">
                                                        Thank you for submitting your Visitor Registration Form for
                                                    </p>
                                                    <p className="mt-1 mb-0 text-center com-font">
                                                        <span className='Heading-main-thanks'>
                                                            {exhibitionName} from {fromDate} to {toDate} </span>
                                                    </p>
                                                    <p className="mb-3 text-center com-font">
                                                        {exhibitionAdd}
                                                    </p>
                                                    <h5 className="mb-4 pt-1" style={{ fontWeight: "bold" }}>
                                                        <strong>Your Visitor Registration {sessionStorage.getItem('VisitorTypeID') == 2 ? 'Numbers are' : 'Number is'}
                                                            {/* <span className='Heading-main-thanks' > 2323232</span> */}
                                                            <span className='Heading-main-thanks' > {sessionStorage.getItem('VisitorTypeID') == 2 ? JSON.parse(sessionStorage.getItem('VisitorDataSS')).VisitorRegistrationNos : JSON.parse(sessionStorage.getItem('VisitorDataSS')).VisitorRegistrationNo}</span>
                                                        </strong>
                                                    </h5>
                                                    <p className="mb-0 mt-1 text-center com-font">
                                                        <strong>Note:</strong>Kindly check your registered email  address for acknowledgement of application.
                                                    </p>
                                                </div>
                                                <div classname="">
                                                    <div classname="mt-1 mb-4">
                                                        <button
                                                            classname="btn btn-next-bot m-1"
                                                            style={{
                                                                background: "#007bff",
                                                                padding: "6px 20px",
                                                                fontSize: 14,
                                                                border: "1px solid #007bff",
                                                                borderRadius: 2,
                                                                color: "#fff",
                                                                marginTop: 16
                                                            }}
                                                            onClick={e => this.onPreviousClick(e)}
                                                        >
                                                            {" "}
                                                            BACK TO EVENT WEBSITE
                                                        </button>

                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </form>
                                </div>
                            </div>
                        </div>
                    </div>


                </div>
                {/* <Footer /> */}
                {/* <!-- CONTAINER END --> */}

            </>
        );
    }
}

export default VisitorThankYou
