import { Component } from "react";
import Loader from "../../../../../utils/Loader";
import { ToastContainer } from "react-toastify";
import { withRouter } from "../../../../../utils/withRouter";
import { connect } from "react-redux";
import { toastError, toastSuccess, exhDescriptionActionId, exhNoteTypeActionId } from "../../../../../utils/constants";
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import Modal from "react-modal";
import moment from "moment";
import AdminDrawer from "../../../../../common_components/admin_module_drawer";
import HeaderWithLogo from "../../../../../common_components/header_with_logo";
import Pagination from "../../../../../utils/pagination";
import { fill_action } from '../../../../../actions/fillCombo_action';
import { getExhNoteDetails_action, insertUpdateExhNote_action } from '../../../../../actions/admin_module_action/master_action/exhibition_master_action/exh_note_action';

class Exh_Note extends Component {
    constructor(props) {
        super(props)
        this.state = {
            isLoading: false,
            currentPage: 1,
            ExihibitionID: '',
            exhNoteList: [],
            DescriptionList: [],
            tempDescriptionList: [],
            NoteTypeList: [],
            tempNoteTypeList: [],
        }
        this.updateItem = this.updateItem.bind(this)
    }

    componentDidMount() {
        this.getFillList(exhDescriptionActionId, '', '', '', '')
        this.getFillList(exhNoteTypeActionId, '', '', '', '')

        this.setState({
            ExihibitionID: this.props.location.state.ExihibitionID != undefined ? (this.props.location.state != null && this.props.location.state.ExihibitionID != null ? this.props.location.state.ExihibitionID : '') : this.props.id != null && this.props.id != undefined ? this.props.id : '',
        })
        setTimeout(() => {
            console.log(this.state.ExihibitionID)

            this.getExhNoteDetails()
        }, 200);
    }

    getFillList(value, userID, keyword, keyvalue) {
        // this.setState({ isLoading: true })
        this.props
            .fill_action(value, (userID == '' || userID == null) ? null : userID, keyword != '' ? keyword : null, keyvalue != '' ? keyvalue : null)
            .then((data) => {
                if (data.error != 1) {
                    if (value == exhDescriptionActionId) {
                        this.setState({
                            DescriptionList: data.data,
                            tempDescriptionList: data.data,
                        })
                    }
                    if (value == exhNoteTypeActionId) {
                        this.setState({
                            NoteTypeList: data.data,
                            tempNoteTypeList: data.data,
                        })
                    }
                }
            })
            .catch((e) => {
                console.log(e);
            });

    }

    updateItem(item) {
        this.setState({ currentPage: item });
    }

    getExhNoteDetails() {
        const { ExihibitionID } = this.state
        this.setState({ isLoading: true })
        this.props.getExhNoteDetails_action(ExihibitionID)
            .then((data) => {
                if (data.error != 1) {
                    console.log("1314", data.data)
                    if (data.data != '') {
                        data.data.map((item, i) => {
                            item.id = i + 1
                            item.isDisplayDescriptionList = false
                        })
                        this.setState({ exhNoteList: data.data })
                    } else {
                        this.setState({ exhNoteList: [{ Description: '', NoteType: '', id: 1, isDisplayDescriptionList: false, isDisplayNoteTypeList: false }] })
                    }
                    this.setState({ isLoading: false })
                }
                else {
                    this.setState({ isLoading: false })
                    toastError(data.msg)
                }
            })
            .catch((err) => {
                console.log(err)
            })
    }

    insertUpdateExhNoteDetails() {
        const { ExihibitionID, exhNoteList } = this.state
        this.setState({ isLoading: true })
        this.props.insertUpdateExhNote_action(ExihibitionID, exhNoteList)
            .then((data) => {
                if (data.error != 1) {
                    this.getExhNoteDetails()
                    toastSuccess("data updated Successfuly!")
                    this.setState({ isLoading: false })
                }
                else {
                    this.setState({ isLoading: false })
                    toastError(data.msg)
                }
            })
            .catch((err) => {
                console.log(err)
            })
    }

    addExhNoteList(i) {
        const { exhNoteList } = this.state
        exhNoteList.splice(
            i + 1, 0, {
            Description: '',
            NoteType: '',
            id: exhNoteList.length + 1,
            isDisplayDescriptionList: false,
            isDisplayNoteTypeList: false
        }
        )
        this.setState({ exhNoteList: exhNoteList })
    }

    deleteExhNoteList(id) {
        const { exhNoteList } = this.state
        const filterArray = exhNoteList.filter(item => item.id != id)
        filterArray.map((item, i) => {
            item.id = i + 1
        })
        this.setState({ exhNoteList: filterArray })
    }

    onExhNoteInput(event, i, value) {
        const { exhNoteList } = this.state
        exhNoteList.map((data, index) => {
            if (i == index) {
                if (value == 'desc') {
                    data.Description = event
                } else {
                    data.NoteType = event
                }
            }
        })
        this.setState({ exhNoteList: exhNoteList })
    }

    // on Description Search
    onDescriptionSearch(e, i) {
        const { DescriptionList, tempDescriptionList, exhNoteList } = this.state

        exhNoteList.map((data, index) => {
            if (i === index) {
                if (e.target.value != '') {
                    var searchDescriptionList = tempDescriptionList.filter(data => {
                        return (
                            data
                                .Description
                                .toLowerCase()
                                .includes(e.target.value.toLowerCase())
                        )
                    })
                    data.isDisplayDescriptionList = true
                    data.Description = e.target.value
                    this.setState({
                        tempDescriptionList: searchDescriptionList
                    })
                } else {
                    data.Description = ''
                    data.isDisplayDescriptionList = true
                    this.setState({
                        tempDescriptionList: DescriptionList
                    })
                }
            }
        })
        this.setState({ exhNoteList: exhNoteList })
    }

    renderDescriptionList(i) {
        const { tempDescriptionList } = this.state
        return (
            <ul class="list-group pincode-drop" style={{ position: 'absolute', zIndex: 1000, maxHeight: '300px', width: '56%' }}>
                {
                    tempDescriptionList.length > 0 && tempDescriptionList.map(data => (
                        <li class="list-group-item"
                            onClick={() => this.mainDescriptionListData(i, data.Description)}
                        >{data.Description}</li>
                    ))
                }
            </ul>
        )
    }

    mainDescriptionListData(i, value) {
        const { exhNoteList } = this.state
        exhNoteList.map((data, index) => {
            if (i === index) {
                data.Description = value
                data.isDisplayDescriptionList = false
            }
        })
        this.setState({ exhNoteList: exhNoteList })
    }


    // on Note Type Search
    onNoteTypeSearch(e, i) {
        const { NoteTypeList, tempNoteTypeList, exhNoteList } = this.state
        exhNoteList.map((data, index) => {
            if (i === index) {
                if (e.target.value != '') {
                    var searchNoteTypeList = tempNoteTypeList.filter(data => {
                        return (
                            data
                                .NoteType
                                .toLowerCase()
                                .includes(e.target.value.toLowerCase())
                        )
                    })
                    data.isDisplayNoteTypeList = true
                    data.NoteType = e.target.value
                    this.setState({
                        tempNoteTypeList: searchNoteTypeList
                    })
                } else {
                    data.NoteType = ''
                    data.isDisplayNoteTypeList = true
                    this.setState({
                        tempNoteTypeList: NoteTypeList
                    })
                }
            }
        })
        this.setState({ exhNoteList: exhNoteList })
    }

    renderNoteTypeList(i) {
        const { tempNoteTypeList } = this.state
        return (
            <ul class="list-group pincode-drop" style={{ position: 'absolute', zIndex: 1000, maxHeight: '300px', width: '19%' }}>
                {
                    tempNoteTypeList.length > 0 && tempNoteTypeList.map(data => (
                        <li class="list-group-item"
                            onClick={() => this.mainNoteTypeListData(i, data.NoteType)}
                        >{data.NoteType}</li>
                    ))
                }
            </ul>
        )
    }

    mainNoteTypeListData(i, value) {
        const { exhNoteList } = this.state
        exhNoteList.map((data, index) => {
            if (i === index) {
                data.isDisplayNoteTypeList = false
                data.NoteType = value
            }
        })
        this.setState({ exhNoteList: exhNoteList })
    }


    record(exhNoteList) {
        return (
            <table className="table border text-nowrap text-md-nowrap table-bordered mb-0">
                <thead>
                    <tr style={{ border: '1px solid #ccc' }}>
                        <th className="text-center" style={{ width: '70%' }}>Description</th>
                        <th className="text-center" style={{ width: '25%' }}>Note Type</th>
                        <th className="text-center" style={{ width: '5%' }} >Action</th>
                    </tr>
                </thead>
                <tbody>
                    {
                        exhNoteList != '' && exhNoteList != null ? (
                            exhNoteList.map((data, i) => (
                                <tr key={i}>
                                    <td >
                                        <input className="form-control"
                                            type="text"
                                            value={data.Description}
                                            onChange={(e) => this.onDescriptionSearch(e, i)}
                                            onClick={(e) => this.onDescriptionSearch(e, i)}
                                        // onChange={(e) => this.onExhNoteInput(e.target.value, i, 'desc')}
                                        />
                                        {data.isDisplayDescriptionList && this.renderDescriptionList(i)}
                                    </td>
                                    <td>
                                        <input className="form-control"
                                            type="text"
                                            value={data.NoteType}
                                            onChange={(e) => this.onNoteTypeSearch(e, i)}
                                            onClick={(e) => this.onNoteTypeSearch(e, i)}
                                        />
                                        {data.isDisplayNoteTypeList && this.renderNoteTypeList(i)}
                                    </td>
                                    <td className="text-center">
                                        <button
                                            title="Add Note"
                                            class="btn btn-sm padding_0"
                                            onClick={() => this.addExhNoteList(i)}
                                        >
                                            <i class="fa fa-plus action_btn_table"></i>
                                        </button>
                                        {this.state.exhNoteList.length != 1 &&
                                            <button
                                                title="Delete Note"
                                                class="btn btn-sm padding_0"
                                                onClick={() => this.deleteExhNoteList(data.id)}
                                            >
                                                <i class="fa fa-trash action_btn_table"></i>
                                            </button>
                                        }
                                    </td>
                                </tr>
                            ))
                        ) : (<tr><td colSpan={6} className='text-center'>Record not found</td></tr>)
                    }
                </tbody>
            </table>)
    }


    render() {
        const { isLoading, exhNoteList, currentPage } = this.state

        const recordsPerPage = 10
        const indexOfLastRecord = currentPage * recordsPerPage;
        const indexOfFirstRecord = indexOfLastRecord - recordsPerPage;
        // Records to be displayed on the current page
        const currentRecords = exhNoteList.slice(indexOfFirstRecord, indexOfLastRecord)
        const nPages = Math.ceil(exhNoteList.length / recordsPerPage)

        return (
            <>

                {isLoading ? (
                    <Loader></Loader>
                ) : (
                    null
                )}

                <ToastContainer />
                <HeaderWithLogo />
                <>
                    <AdminDrawer />


                    <div className="main-container p-2">
                        <div className="row" style={{ width: '97%', margin: 'auto' }}>
                            <div className="asc" style={{ padding: '20px 0' }}>
                                <div className="table-responsive">
                                    {this.record(exhNoteList)}
                                </div>
                            </div>
                            <div class="col-md-12">
                                <button type="button" class="btn btn-primary rounded-0" style={{ float: 'right' }}
                                    onClick={() => this.insertUpdateExhNoteDetails()}>
                                    Save
                                </button>
                            </div>
                            {/* <div className="mt-2 float-left">
                                {exhNoteList.length > 0 ? <Pagination
                                    nPages={nPages}
                                    currentPage={currentPage}
                                    setCurrentPage={this.updateItem} /> : <></>}

                            </div> */}
                        </div>
                    </div>

                </>

            </>
        );
    }
}

export default withRouter(connect(null, { fill_action, getExhNoteDetails_action, insertUpdateExhNote_action })(Exh_Note));